import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "cgm-detail__cancel-test-cta-p" }
const _hoisted_2 = { class: "l-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.medicalTest)
      ? (_openBlock(), _createBlock(_component_LoadingComponent, {
          key: 0,
          "spinner-color": "lavender-700"
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.medicalTest)
            ? (_openBlock(), _createBlock(_component_HeadingComponent, {
                key: 0,
                class: "cgm-detail__heading",
                title: _ctx.medicalTest.name,
                breadcrumb: _ctx.breadcrumb,
                tabs: _ctx.tabsMenu,
                "heading-icon": "icon-bar_chart_circle",
                tag: _ctx.isMedicalTestCanceled ? _ctx.$t('medicalTest.state.CANCELED') : '',
                "tag-style": _ctx.isMedicalTestCanceled ? 'warning' : ''
              }, {
                right: _withCtx(() => [
                  (_ctx.isMedicalTestFinished)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        type: "button",
                        class: _normalizeClass({ 'p-button-icon p-button-info': true, 'p-disabled': _ctx.waitingForReport }),
                        disabled: _ctx.waitingForReport,
                        onClick: _ctx.onDownloadReportClicked
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.downloadReport')), 1)
                        ]),
                        _: 1
                      }, 8, ["class", "disabled", "onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.isMedicalTestCancelable)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "cgm-detail__cancel-test-cta",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDialog = true))
                      }, [
                        _cache[3] || (_cache[3] = _createElementVNode("i", {
                          class: "icon-trash_full cgm-detail__cancel-test-cta-icon",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('cgmDetail.cancel-medical-test')), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["title", "breadcrumb", "tabs", "tag", "tag-style"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_view)
          ])
        ], 64)),
    _createVNode(_component_Dialog, {
      visible: _ctx.showDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showDialog) = $event)),
      header: _ctx.$t('medicalTest.cancel-test'),
      modal: true,
      class: "p-fluid"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('common.get-back'),
          class: "p-button-secondary p-button-medium",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = false))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('medicalTest.cancel-test-confirm'),
          class: "p-button p-button-medium p-button-secondary cancel-test-confirm-button p-button-danger",
          loading: _ctx.submitting,
          onClick: _ctx.cancelMedicalTest
        }, null, 8, ["label", "loading", "onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('medicalTest.cancel-test-prompt')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('medicalTest.cancel-test-no-way-back')), 1)
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}