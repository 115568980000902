
import { computed, defineComponent, onMounted, Ref, ref } from 'vue';
import HeadingComponent from '@/components/HeadingComponent.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import { medicalTestService } from '@/services/MedicalTestService';
import { useRoute, onBeforeRouteLeave, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { MedicalTest, MedicalTestState } from '@/models/MedicalTest';
import { useProfileStore } from '@/store/profile.module';
import { useCgmDetailStore } from '@/store/cgmDetail.module';
import { userService } from '@/services/UserService';
import { ServiceError } from '@/services/util/ServiceError';
import { patientService } from '@/services/PatientService';
import { measurementService } from '@/services/MeasurementService';

export default defineComponent({
  components: {
    HeadingComponent,
    LoadingComponent,
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const storeCGM = useCgmDetailStore();
    const storeProfile = useProfileStore();
    const loadingMedicalTestData = ref(false);
    const patientId = route.params.patientId as string;
    const medicalTestId = route.params.medicalTest as string;
    const routeBreadcrumb = route.meta.breadcrumb as any[];
    const medicalTest: Ref<MedicalTest | undefined> = ref();
    const breadcrumb = ref([] as Array<any>);
    const tabsMenu = ref([
      { label: t('cgmDetail.follow-up'), to: 'follow-up' },
      { label: t('cgmDetail.events'), to: 'events' },
      { label: t('cgmDetail.patient-info'), to: 'patient-info' },
      {
        label: t('cgmDetail.final-analysis'),
        to: 'final-analysis',
      },
    ]);
    const showDialog = ref(false);
    const submitting = ref(false);
    const waitingForReport = ref(false);

    const cancelMedicalTest = async () => {
      submitting.value = true;
      await medicalTestService.cancelMedicalTest(medicalTestId, patientId);
      await router.push({ name: 'medical-tests', params: { patientId } });
      submitting.value = false;
    };

    onMounted(async () => {
      loadingMedicalTestData.value = true;
      await loadMedicalTestData();
      loadingMedicalTestData.value = false;
    });

    onBeforeRouteLeave((to) => {
      if (to.name !== 'event-detail') {
        storeCGM.resetMedicalTestSelectedAll();
      }
    });

    const isMedicalTestCancelable = computed(() => {
      const isMedicalTestInCancelableState =
        medicalTest.value?.state === 'STARTED' || medicalTest.value?.state === 'IN_PROGRESS';
      const isUserMedicalTestMainSpecialist = storeProfile.specialistId === medicalTest.value?.specialistId;
      return isMedicalTestInCancelableState && isUserMedicalTestMainSpecialist;
    });

    const isMedicalTestCanceled = computed(() => {
      return medicalTest.value?.state === 'CANCELED';
    });

    const isMedicalTestFinished = computed(() => {
      return medicalTest.value?.state === MedicalTestState.FINISHED;
    });

    async function loadMedicalTestData() {
      const result = await medicalTestService.find(patientId, medicalTestId);
      if (!(result instanceof ServiceError)) {
        storeCGM.setMedicalTestSelected(result);
        breadcrumb.value = [
          { label: t(routeBreadcrumb[0].parent), to: `/patient/${patientId}/medical-tests` },
          {
            label: result.name,
            to: '#',
            disabled: true,
          },
        ];
        medicalTest.value = Object.assign({}, result);
      }
      let timezone = storeProfile.timezone;
      const medicalTestPatient = await patientService.find(patientId);
      if (!(medicalTestPatient instanceof ServiceError)) {
        const medicalTestUser = await userService.find(medicalTestPatient?.userId);
        if (!(medicalTestUser instanceof ServiceError)) {
          timezone = medicalTestUser.timezone;
        }
      }
      storeCGM.setMedicalTestSelectedUserTimezone(timezone);
    }

    const onDownloadReportClicked = async () => {
      waitingForReport.value = true;
      if (medicalTest.value) {
        const result = await measurementService.getFinalReportPdfFile(
          patientId,
          medicalTest.value.startDate,
          medicalTest.value.finishDate,
        );
        waitingForReport.value = false;

        downloadFile(result?.file);
      }
    };

    const downloadFile = (fileBase64: string) => {
      const a = document.createElement('a'); //Create <a>
      a.href = 'data:application/pdf;base64,' + fileBase64; //File Base64 Goes here
      a.download = medicalTest.value?.name ? medicalTest.value?.name : 'Report' + '.pdf'; //File name Here
      a.click(); //Downloaded file
    };

    return {
      medicalTest,
      breadcrumb,
      tabsMenu,
      loadingMedicalTestData,
      showDialog,
      submitting,
      isMedicalTestCancelable,
      isMedicalTestCanceled,
      isMedicalTestFinished,
      waitingForReport,
      cancelMedicalTest,
      onDownloadReportClicked,
    };
  },
});
